.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-style: normal;
    font-size: 40px;
    background-color: darkgreen;

  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: darkgreen;
  }
  
  .popup-content {
    margin-top: 20px;
    background-color: darkgreen;
  }

  .texts{
    font-weight: bold;
    color: white;
    text-align: center;
    font-size: 16px;
    background-color: darkgreen;
  }
