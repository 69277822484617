/*corrige header*/

h1,
h2,
h3 {
  margin-top: 20px !important;
  font-weight: 100 !important;
}

.mv-10 {
  margin: 10px 0;
}

/*popover*/
.pt-dark {
  background-color: 'red';
}
/* inputs */

.form-control,
.single-line {
  border: 1px solid #c9d3dd;
  border-radius: 4px;
  height: 38px;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  height: 38px;
}

input[type='text']:disabled {
  background-color: transparent !important;
}

/* react-widget */
/* .rw-select>* {
    width: 100% !important;
    margin-right: 15px !important;
    height: 99% !important;
} */

/* react-widget - DropdownList */
/* .rw-widget-container{
    border: 1px solid #c9d3dd !important;
} */
.rw-dropdown-list-input {
  height: 38px !important;
}

/* react-widget - DateTimePicker */
.rw-widget > .rw-widget-container {
  height: 38px !important;
}

.rw-widget-picker > .rw-select {
  width: 5% !important;
}

/**/
.table-no-border tr {
  height: 46px !important;
}

.table-no-border tr td {
  vertical-align: middle !important;
  border: none !important;
}
.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}
.vertical-align {
  display: flex;
  align-items: center;
}
/**/
.Select-control {
  height: 38px !important;
  border: 1px solid #c9d3dd !important;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  line-height: 38px !important;
}

.Select-input > input {
  line-height: 22px !important;
}

.switch.on.switchBg {
  background: #18a689;
}

.swal-button--confirm {
  background-color: #18a689 !important;
}

.Select-menu-outer {
  z-index: 1000;
}

.white {
  color: #fff;
}
.bg-login {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.vertical-center {
  min-height: 50%; /* Fallback for browsers do NOT support vh unit */
  min-height: 50vh; /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}

/* ibox-content */
.ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.ibox-content h5 {
  padding-top: 10px;
  font-size: 16px;
}

/** ANTD Paginação Table **/
.ant-table-pagination.ant-pagination {
  margin-right: 30px !important;
}

/** ANTD Espaçamento Table **/
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 10px !important;
}

.equal {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.swal-button--cancel.button-custom {
  color: #ffffff !important;
  background-color: #ed5565 !important;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent !important;
}

.div-text-grid {
  text-align: left;
}

.ant-transfer {
  text-align: center !important;
}

.ant-transfer-list-content-item {
  text-align: left !important;
}

.ant-transfer-list-header {
  text-align: left !important;
}

.color-link-editar {
  color: #676a6c;
}

@font-face {
  font-family: 'DejaVu Sans';
  src:
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('./fonts/DejaVuSans.ttf')
    format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  src: url('./fonts/DejaVuSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-style: italic;
  src: url('./fonts/DejaVuSans-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'DejaVu Sans';
  font-weight: bold;
  font-style: italic;
  src: url('./fonts/DejaVuSans-Oblique.ttf') format('truetype');
}

.k-button + .k-button {
  margin-left: 5px;
}

.k-pager-numbers .k-state-selected {
  background-color: #1ab394 !important;
}

.k-grid-header .k-i-sort-desc-sm {
  color: #1ab394 !important;
}

.k-grid-header .k-i-sort-asc-sm {
  color: #1ab394 !important;
}

.k-grid ::selection {
  background-color: #469c7f !important;
  color: #fff !important;
}

.ant-tabs.ant-tabs-top.ant-tabs-card.ant-tabs-no-animation {
  overflow: unset;
}

.ant-radio-group {
  margin-top: -7px !important;
}

/* PDF js style */
.pdf-toolbar {
  max-width: 600px;
  margin: 0 auto;
}

.react-loading {
  background: #8360c3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #18a689, #8360c3); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #18a689,
    #8360c3
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  font-size: 26px;
  height: 100vh !important;
}

.center-version {
  display: block;
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}
.icon-gold {
  color: yellow;
}

.ant-table table,
table.table.table-responsive {
  font-size: 13px !important;
}

.ant-modal-close .ant-modal-close-x {
  display: flex !important;
  align-items: center !important;
  justify-content: space-around;
}

.tron-primary {
  color: #88a725;
}

.tron-primary a {
  color: #88a725;
}

.navbar-static-side {
  z-index: 999;
}

.ant-upload.ant-upload-drag {
  height: 145 !important;
}

.ml-1 {
  margin-left: 1rem;
}

.ant-pagination-item-link > i {
  padding: 10 !important;
}

.button-distance {
  display: flex;
  justify-content: space-between;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 0px 10px 10px 10px;
}

.steps-action {
  margin-top: 24px;
}

.label-cursor {
  cursor: pointer;
}

.text-navy h1,
.text-navy h2,
.text-navy h3,
.text-navy h4,
.text-navy h5,
.text-navy h6 {
  color: #1ab394 !important;
}

.text-primary h1,
.text-primary h2,
.text-primary h3,
.text-primary h4,
.text-primary h5,
.text-primary h6 {
  color: inherit !important;
}

.text-success h1,
.text-success h2,
.text-success h3,
.text-success h4,
.text-success h5,
.text-success h6 {
  color: #1c84c6 !important;
}
.text-info h1,
.text-info h2,
.text-info h3,
.text-info h4,
.text-info h5,
.text-info h6 {
  color: #23c6c8 !important;
}

.text-warning h1,
.text-warning h2,
.text-warning h3,
.text-warning h4,
.text-warning h5,
.text-warning h6 {
  color: #f8ac59 !important;
}
.text-danger h1,
.text-danger h2,
.text-danger h3,
.text-danger h4,
.text-danger h5,
.text-danger h6 {
  color: #ed5565 !important;
}
.text-muted h1,
.text-muted h2,
.text-muted h3,
.text-muted h4,
.text-muted h5,
.text-muted h6 {
  color: #888888 !important;
}
.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #ffffff !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.mt-1 {
  margin-top: 1.2em !important;
}

.mb-1 {
  margin-bottom: 1.2em !important;
}

.text-w600 {
  font-weight: 600 !important;
}

.float-right {
  float: right !important;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.mr-1 {
  margin-right: 0.5em !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

h3 {
  font-weight: 500 !important;
}

.yellow-bg,
.bg-warning {
  background-color: #f8ac59 !important;
  color: #ffffff !important;
}

.bg-warning2 {
  background-color: #392eae !important;
  color: #ffffff !important;
}

.bg-success {
  background-color: #027e2d !important;
  color: #ffffff !important;
}

.red-bg,
.bg-danger {
  background-color: #ed5565 !important;
  color: #ffffff;
}

.black-bg {
  background-color: #262626;
}

.card-box {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-box::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-box:hover::after {
  opacity: 1;
}

.container-error {
  margin-top: 50px;
  box-shadow: 0 0 30px black;
  padding: 0 15px 0 15px;
  background-color: #f3f3f4;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.container-popover {
  max-width: 300px;
}

.map-style {
  overflow: hidden;
  border-radius: 20px;
  border-width: 1px;
}
