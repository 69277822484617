.coleta-style {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
  background-color: #e8e8e8;
  height: 100vh;
  width: 100vw;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.container-coletor {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.ibox-coletor {
  display: flex;
  align-items: center;
  height: 90%;
  width: 60%;
  min-width: 800px;
}

.flex-coletor-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-coletor-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

.flex-logo {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-bottom: 25px;
}
.flex-inline {
  display: table-cell;
}

.logo-coletor {
  height: 170px;
}

.flex-row-num-123 {
  flex: 1;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 2px;
}
.flex-row-num-456 {
  flex-direction: row;
  margin-top: 2px;
  margin-bottom: 2px;
}

.input-empresa {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline;
  width: 70%;
  height: 38px;
  padding: 4px 11px;
  color: #676a6c;
  font-size: 13px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c9d3dd;
  border-radius: 4px;
  transition: all 0.3s;
  text-align: center;
}

.input-empresa:hover {
  border-color: #afc74e;
  border-right-width: 1px !important;
}

.input-empresa:focus {
  border-color: #afc74e;
  border-right-width: 1px !important;
  outline: 0px;
  box-shadow: 0 0 0 2px rgba(152, 186, 42, 0.2);
}

.input-senha-password {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #676a6c;
  font-size: 13px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
}

.input-senha-password .input-senha:not(:last-child) {
  padding-right: 30px;
}

.input-senha {
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 85%;
  height: 38px;
  padding: 4px 11px;
  color: #676a6c;
  font-size: 13px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #c9d3dd;
  border-radius: 4px;
  transition: all 0.3s;
}

.input-senha:hover {
  border-color: #afc74e;
  border-right-width: 1px !important;
}

.input-senha:focus {
  border-color: #afc74e;
  border-right-width: 1px !important;
  outline: 0px;
  box-shadow: 0 0 0 2px rgba(152, 186, 42, 0.2);
}

.content-input-senha {
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-style: normal;
  display: flex;
}

.icon-eye-senha {
  position: absolute;
  right: 22px;
}

.typography-timer {
  font-size: 32px;
  font-weight: bold;
}

.typography-day {
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  padding-top: 5px;
}

.typography-label-input {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
}
